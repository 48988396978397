<template>
  <div class="sect_n_sort">
    <div class="top" v-if="!realTimeShow">
      <select name="" @change="setRange">
        <option value="today" :selected="range === 'today'">오늘</option>
        <option value="week" :selected="range === 'week'">한주</option>
        <option value="month" :selected="range === 'month'">한달</option>
        <option value="year" :selected="range === 'year'">일년</option>
      </select>
    </div>
    <div class="bottom">
      <!--뉴스-통합-->
      <div v-if="totalShow && !isCategory && !isMenu">
        <router-link
          to=""
          class="news_sort"
          :class="{ on: sort === 'default' }"
          @click.native="setSort('default', $event)"
          >리뷰어/독자들의 뉴스 중요도순</router-link
        >
        <router-link
          to=""
          class="news_sort"
          :class="{ on: sort === 'bad' }"
          @click.native="setSort('bad', $event)"
          >나쁜 뉴스순</router-link
        >
      </div>
      <div v-if="totalShow && !isCategory && !isMenu">
        <router-link
          to=""
          class="news_sort"
          :class="{ on: sort === 'trust' }"
          @click.native="setSort('trust', $event)"
          >리뷰어 신뢰도순</router-link
        >
        <router-link
          to=""
          class="news_sort"
          :class="{ on: sort === 'reply' }"
          @click.native="setSort('reply', $event)"
          >댓글수순</router-link
        >
        <router-link
          to=""
          class="news_sort"
          :class="{ on: sort === 'desc' }"
          @click.native="setSort('desc', $event)"
          >최신순</router-link
        >
      </div>
      <!--뉴스 언론사 뉴스, 언론사 스포츠, 나도 미디어 (일반)-->
      <div v-if="(newsShow || sportShow || mediaShow) && !isCategory && isMenu">
        <router-link
          to=""
          class="news_sort"
          :class="{ on: sort === 'partTopScore' }"
          @click.native="setSort('partTopScore', $event)"
          >각 분야 리뷰어/독자들의 뉴스 중요도 1위</router-link
        >
        <router-link
          to=""
          class="news_sort"
          :class="{ on: sort === 'bad' }"
          @click.native="setSort('bad', $event)"
          >나쁜 뉴스 1위</router-link
        >
      </div>
      <div v-if="(newsShow || sportShow || mediaShow) && !isCategory && isMenu">
        <router-link
          to=""
          class="news_sort"
          :class="{ on: sort === 'partTopTrust' }"
          @click.native="setSort('partTopTrust', $event)"
          >리뷰어 신뢰도 1위</router-link
        >
        <router-link
          to=""
          class="news_sort"
          :class="{ on: sort === 'reply' }"
          @click.native="setSort('reply', $event)"
          >댓글수 1위</router-link
        >
        <router-link
          to=""
          class="news_sort"
          :class="{ on: sort === 'desc' }"
          @click.native="setSort('desc', $event)"
          >최신</router-link
        >
      </div>
      <div v-if="(newsShow || sportShow || mediaShow) && isCategory && isMenu">
        <router-link
          to=""
          class="news_sort"
          :class="{ on: sort === 'default' }"
          @click.native="setSort('default', $event)"
          >리뷰어/독자들의 뉴스 중요도순</router-link
        >
        <router-link
          to=""
          class="news_sort"
          :class="{ on: sort === 'bad' }"
          @click.native="setSort('bad', $event)"
          >나쁜 뉴스순</router-link
        >
      </div>
      <div v-if="(newsShow || sportShow || mediaShow) && isCategory && isMenu">
        <router-link
          to=""
          class="news_sort"
          :class="{ on: sort === 'trust' }"
          @click.native="setSort('trust', $event)"
          >리뷰어 신뢰도순</router-link
        >
        <router-link
          to=""
          class="news_sort"
          :class="{ on: sort === 'reply' }"
          @click.native="setSort('reply', $event)"
          >댓글수순</router-link
        >
        <router-link
          to=""
          class="news_sort"
          :class="{ on: sort === 'desc' }"
          @click.native="setSort('desc', $event)"
          >최신순</router-link
        >
      </div>
      <!--뉴스-언론사뉴스(특정)-->
      <!-- <div>
                            <router-link to="" class="on">리뷰어/독자들의 뉴스 중요도순</router-link>
                            <router-link to="">나쁜 뉴스순</router-link>
                        </div>
                        <div>
                            <router-link to="">리뷰어 신뢰도순</router-link>
                            <router-link to="">댓글수순</router-link>
                            <router-link to="">최신순</router-link>
                        </div> -->
      <!--뉴스-언론사스포츠(특정)-->
      <!--<div>
                            <router-link to="" class="on">리뷰어/독자들의 뉴스 중요도순</router-link>
                            <router-link to="">나쁜 뉴스순</router-link>
                        </div>
                        <div>
                            <router-link to="">리뷰어 신뢰도순</router-link>
                            <router-link to="">댓글수순</router-link>
                            <router-link to="">최신순</router-link>
                        </div>-->
      <!--뉴스-나도미디어(특정)-->
      <!--<div>
                            <router-link to="" class="on">독자들의 리뷰 중요도순</router-link>
                            <router-link to="">댓글수순</router-link>
                        </div>
                        <div>
                            <router-link to="">리뷰어 신뢰도순</router-link>
                            <router-link to="">최신순</router-link>
                        </div>-->
      <!--뉴스-실시간리뷰어속보-->
      <div v-if="realTimeShow">
        <router-link
          to=""
          class="news_sort on"
          @click.native="setSort('desc', $event)"
          >최신순</router-link
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: {
    sort: {
      type: String,
      default: "default",
    },
    range: {
      type: String,
      default: "today",
    },
    totalShow: {
      type: Boolean,
      default: false,
    },
    newsShow: {
      type: Boolean,
      default: false,
    },
    sportShow: {
      type: Boolean,
      default: false,
    },
    mediaShow: {
      type: Boolean,
      default: false,
    },
    realTimeShow: {
      type: Boolean,
      default: false,
    },
    isCategory: {
      type: Boolean,
      default: false,
    },
    isMenu: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isSortSet() {
      if (this.sort === "partTopTrust" || this.sort === "partTopScore") {
        return true;
      }
      return false;
    },
  },
  methods: {
    setSort(sort, event) {
      const sortList = document.querySelectorAll(".news_sort");
      [...sortList].map((sort) => sort.classList.remove("on"));
      event.target.classList.add("on");
      this.$emit("setSort", sort);
    },
    setRange(e) {
      this.$emit("setRange", e.target.value);
    },
  },
};
</script>

<style></style>
