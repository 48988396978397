<template>
  <div style="margin-top: 10px">
    <VueScriptComponent
      script='
    <script type="text/javascript">
      var zone = "10881342";
      var adType = "banner";
      var bannerType = "bottom";
      var width = "300";
      var height = "250";
      var id = "mobwith_" + zone;
      var pb = "";
      var position = "";

      new MobWithAd({
        id: id,
        zone: zone,
        adType: adType,
        bannerType: bannerType,
        width: width,
        height: height,
        pb: pb,
        position: position,
      });
      document.querySelector("#mobwith_10881342").style.margin = "0  auto";
    </script>
    '
    />
  </div>
</template>

<script>
import VueScriptComponent from "vue-script-component";
export default {
  components: {
    VueScriptComponent,
  },
};
</script>

<style></style>
