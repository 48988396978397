import { render, staticRenderFns } from "./NewsMain.vue?vue&type=template&id=729ff830&scoped=true&"
import script from "./NewsMain.vue?vue&type=script&lang=js&"
export * from "./NewsMain.vue?vue&type=script&lang=js&"
import style0 from "./NewsMain.vue?vue&type=style&index=0&id=729ff830&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "729ff830",
  null
  
)

export default component.exports