<template>
  <div>
    <div class="rn_ms_section">
      <!---->
      <!--뉴스-->
      <div class="cate_n_top">
        <div class="newsflash" v-if="parseInt(menu) != parseInt(realTime)">
          <b>실시간 리뷰어 속보</b> :
          <swiper
            class="swiper realTime realtime_news"
            :options="swiperOption"
            ref="swiper"
          >
            <swiper-slide v-for="real in realTimeNewsList" :key="real.seq">
              <p :data-seq="real.seq">
                {{ real.title }}
              </p>
            </swiper-slide>
          </swiper>
        </div>
        <div class="newsflash" v-else>
          <b>실시간 리뷰어 속보</b>
          <router-link to="" class="right_btn" @click.native="goInit"
            >뉴스카테고리</router-link
          >
        </div>
        <ul class="catelist" v-if="!realTimeShow">
          <li :class="{ on: parseInt(menu) === 0 }">
            <router-link
              to=""
              data-parent-seq="0"
              @click.native="toMenu(0, $event)"
              >전체</router-link
            >
          </li>
          <li
            v-for="list in newsLeve1"
            :key="list.parent_seq"
            :menu="menu"
            :parent_seq="list.parent_seq"
            :class="{ on: parseInt(menu) === parseInt(list.parent_seq) }"
          >
            <router-link
              to=""
              :data-parent-seq="list.parent_seq"
              @click.native="toMenu(list.parent_seq, $event)"
              >{{ list.parent_title }}</router-link
            >
          </li>
        </ul>
      </div>

      <!---->
      <div class="rn_ms_box_9">
        <div
          class="sect_menubar"
          :class="{ news_category: newsShow || sportShow || mediaShow }"
        >
          <!--언론사뉴스-->

          <ul class="menubar depth_5 category_list" v-if="newsShow">
            <template v-for="(list, index) in this.newsCategory">
              <li
                class=""
                v-if="newsShow && index <= 4"
                :key="`${list.child_seq}_depth_5`"
                :id="`category_${list.child_seq}`"
                :class="{ on: parseInt(category) === parseInt(list.child_seq) }"
              >
                <router-link
                  to=""
                  @click.native="setCategory(list.child_seq)"
                  >{{ list.child_title }}</router-link
                >
              </li>
            </template>
          </ul>
          <ul class="menubar depth_4 category_list" v-if="newsShow">
            <template v-for="(list, index) in this.newsCategory">
              <li
                v-if="newsShow && index >= 5"
                :key="`${list.child_seq}_depth_4`"
                :id="`category_${list.child_seq}`"
                :class="{ on: parseInt(category) === parseInt(list.child_seq) }"
              >
                <router-link
                  to=""
                  @click.native="setCategory(list.child_seq)"
                  >{{ list.child_title }}</router-link
                >
              </li>
            </template>
          </ul>

          <!--언론사스포츠-->

          <ul class="menubar depth_6 category_list" v-if="sportShow">
            <template v-for="list in this.sportCategory">
              <li
                v-if="sportShow"
                :style="`width:${100 / sportCategory.length}%`"
                :key="`${list.child_seq}_depth_6`"
                :id="`category_${list.child_seq}`"
                :class="{ on: parseInt(category) === parseInt(list.child_seq) }"
              >
                <router-link
                  to=""
                  @click.native="setCategory(list.child_seq)"
                  >{{ list.child_title }}</router-link
                >
              </li>
            </template>
          </ul>

          <!--나도미디어-->
          <ul class="menubar depth_5 media category_list" v-if="mediaShow">
            <template v-for="(list, index) in this.mediaCategory">
              <li
                v-if="mediaShow && index <= 4"
                :key="`${list.child_seq}_media_depth_5`"
                :id="`category_${list.child_seq}`"
                :class="{ on: parseInt(category) === parseInt(list.child_seq) }"
              >
                <router-link
                  to=""
                  @click.native="setCategory(list.child_seq)"
                  >{{ list.child_title }}</router-link
                >
              </li>
            </template>
          </ul>
          <ul class="menubar depth_4 media category_list" v-if="mediaShow">
            <template v-for="(list, index) in this.mediaCategory">
              <li
                v-if="mediaShow && index >= 5 && index <= 8"
                :key="`${list.child_seq}_media_depth_4`"
                :id="`category_${list.child_seq}`"
                :class="{ on: parseInt(category) === parseInt(list.child_seq) }"
              >
                <router-link
                  to=""
                  @click.native="setCategory(list.child_seq)"
                  >{{ list.child_title }}</router-link
                >
              </li>
            </template>
          </ul>
          <ul class="menubar depth_6 category_list" v-if="mediaShow">
            <template v-for="(list, index) in this.mediaCategory">
              <li
                v-if="mediaShow && index >= 9"
                :key="`${list.child_seq}_depth_6`"
                :id="`category_${list.child_seq}`"
                :class="{ on: parseInt(category) === parseInt(list.child_seq) }"
              >
                <router-link
                  to=""
                  @click.native="setCategory(list.child_seq)"
                  >{{ list.child_title }}</router-link
                >
              </li>
            </template>
          </ul>
        </div>
        <!---->
        <div class="rn_ms_empty_10"></div>
        <!-- -->
        <ReviewNewsMainSort
          :totalShow="totalShow"
          :newsShow="newsShow"
          :sportShow="sportShow"
          :mediaShow="mediaShow"
          :isCategory="parseInt(category) > 0"
          :isMenu="parseInt(menu) > 0"
          :realTimeShow="realTimeShow"
          :sort="sort"
          :range="range"
          @setSort="setSort"
          @setRange="setRange"
        />
        <!-- -->
        <MobAd320X100 v-if="mediaShow || realTimeShow" />
        <div
          class="location"
          v-if="!totalShow && !realTimeShow && parseInt(category) <= 0"
        >
          <span>뉴스</span> > <span class="on">{{ menuName }}</span>
        </div>
        <div class="location" v-else-if="parseInt(category) > 0">
          <span>뉴스</span> >
          <span class="second shape_yellow" @click="setCategory(category)">{{
            menuName
          }}</span>
          >
          <span class="on">{{ categoryName }}</span>
        </div>
        <!-- -->
        <div
          class="sect_list"
          v-for="(item, index) in listArray"
          :key="item.seq"
        >
          <ReviewNewsListItem
            :index="parseInt(rank) !== 0 ? parseInt(rank) : index + 1"
            :item="item"
            :totalShow="totalShow"
            :realTimeShow="realTimeShow"
            :isCategory="parseInt(category) > 0"
            :menu="parseInt(menu)"
            :nowPage="`${domain}${pageSnsUrl(item.seq)}`"
            :paramsQuery="paramsQuery(item.seq)"
            :ref="`listItem_${item.seq}`"
            :id="`listItem_${item.seq}`"
            @goDetailList="goDetailList"
            @setSeq="setSeq"
          />
        </div>
        <div class="sect_list none_data" v-if="listArray.length <= 0">
          <p>검색된 리뷰가 없습니다.</p>
        </div>
        <!-- -->
        <div class="rn_more" v-if="totalPage > page">
          <router-link to="" @click.native="getNewsList()"
            >{{ limit }}개 더보기<img
              src="@/assets/images/sub/my_down_ico.png"
              alt=""
          /></router-link>
        </div>
        <div
          class="rn_more"
          v-if="
            totalPage <= page && listArray.length <= 1 && parseInt(seq) !== 0
          "
        >
          <router-link to="" @click.native="resetSeq"
            >목록으로<img src="@/assets/images/sub/my_down_ico.png" alt=""
          /></router-link>
        </div>
      </div>
      <!---->
    </div>
    <MobAd320X250bottom
      v-if="(mediaShow || realTimeShow) && listArray.length > 0"
    />
  </div>
</template>

<script>
import { mapState } from "vuex";
import _sortBy from "lodash/sortBy";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import ReviewNewsListItem from "@/components/review/state/ReviewNewsListItem";
import ReviewNewsMainSort from "@/components/review/state/ReviewNewsMainSort";
import MobAd320X100 from "@/components/ad/MobAd320X100";
import MobAd320X250bottom from "@/components/ad/MobAd320X250bottom";
let vm = null;
export default {
  data() {
    return {
      seq: parseInt(this.$route.query.seq) || 0,
      category: parseInt(this.$route.query.category) || 0,
      newsMenu: parseInt(this.$route.params.menu) || 0,
      page: parseInt(this.$route.query.page) || 0,
      news: parseInt(process.env.VUE_APP_NEWS),
      sport: parseInt(process.env.VUE_APP_NEWS_SPORT),
      media: parseInt(process.env.VUE_APP_NEWS_MEDIA),
      realTime: parseInt(process.env.VUE_APP_NEWS_REAL_TIME),
      sort: "default",
      range: "today",
      newsShow: false,
      sportShow: false,
      mediaShow: false,
      realTimeShow: false,
      totalShow: true,
      menuName: null,
      categoryTitle: null,
      domain: process.env.VUE_APP_API_URL,
      pageName: "/review/news_main",
      swiperOption: {
        direction: "vertical",
        initialSlide: 0,
        slidesPerView: "auto",
        speed: 1000,
        loop: true,
        pagination: false,
        autoplay: {
          delay: 1500,
          disableOnInteraction: false, //마우스온이나 수동전환시 오토플레이가 플리는것을 방지
        },
        on: {
          click: function (e) {
            const id = e.target.dataset.seq;
            vm.goRealTime(id);
          },
        },
      },
    };
  },
  mounted() {
    vm = this;
    this.Main();
    localStorage.removeItem("news"); // 뉴스 원문 번소 삭제 초기화
    this.$nextTick(function () {
      if (parseInt(this.menu) !== this.realTime) {
        if (this.$refs.swiper.$swiper) {
          console.log(this.$refs.swiper.$swiper);
          this.$refs.swiper.$swiper.slideTo(0);
          this.$refs.swiper.$swiper.autoplay.stop();
          this.$refs.swiper.$swiper.autoplay.start();
        }
      }
      this.isShowAd = true;
    });
  },
  components: {
    ReviewNewsListItem,
    ReviewNewsMainSort,
    Swiper,
    SwiperSlide,
    MobAd320X100,
    MobAd320X250bottom,
  },
  computed: {
    ...mapState("review", [
      "newsMenuList",
      "realTimeNewsList",
      "result",
      "msg",
      "listArray",
      "totalPage",
      "limit",
      "rank",
    ]),
    menu: {
      get() {
        return this.newsMenu;
      },
      set(value) {
        this.newsMenu = value;
      },
    },
    newsLeve1() {
      let newsLeve1 = [];
      let tmp = null;
      if (this.newsMenuList) {
        this.newsMenuList.map((level1) => {
          if (parseInt(level1.parent_seq) !== parseInt(tmp)) {
            newsLeve1.push(level1);
          }
          tmp = level1.parent_seq;
        });
      }
      return newsLeve1;
    },
    newsCategory() {
      const data = this.newsMenuList.filter(
        (news) => parseInt(news.parent_seq) === this.news
      );
      data.map((data) => {
        data.child_sort = parseInt(data.child_sort);
      });
      return _sortBy(data, "child_sort");
    },
    sportCategory() {
      const data = this.newsMenuList.filter(
        (news) => parseInt(news.parent_seq) === this.sport
      );
      data.map((data) => {
        data.child_sort = parseInt(data.child_sort);
      });
      return _sortBy(data, "child_sort");
    },
    mediaCategory() {
      const data = this.newsMenuList.filter(
        (news) => parseInt(news.parent_seq) === this.media
      );
      data.map((data) => {
        data.child_sort = parseInt(data.child_sort);
      });
      return _sortBy(data, "child_sort");
    },
    pageUrl() {
      // console.log(
      //   `${this.pageName}/${this.menu}?category=${
      //     this.category === null ? 0 : this.category
      //   }&seq=${this.seq}`
      // );
      return `${this.pageName}/${this.menu}?category=${
        this.category === null ? 0 : this.category
      }&seq=${this.seq}`;
    },
    categoryName: {
      get() {
        if (
          parseInt(this.category) > 0 &&
          this.newsMenuList.length > 0 &&
          parseInt(this.menu) !== parseInt(this.realTime)
        ) {
          const newManu = this.newsMenuList.filter(
            (menu) => parseInt(menu.child_seq) === parseInt(this.category)
          );
          this.categoryTitle = newManu[0].child_title;
        }
        return this.categoryTitle;
      },
      set(value) {
        this.categoryTitle = value;
      },
    },
  },
  methods: {
    pageSnsUrl(seq) {
      const category = this.listArray.filter(
        (list) => parseInt(list.seq) === parseInt(seq)
      );
      if (category.length > 0) {
        return `${this.pageName}/${category[0].option_category1}?category=${
          category[0].option_category2 === null
            ? 0
            : category[0].option_category2
        }`;
      }
      return `${this.pageName}/${this.menu}?category=${
        this.category === null ? 0 : this.category
      }`;
    },
    paramsQuery(seq) {
      const category = this.listArray.filter(
        (list) => parseInt(list.seq) === parseInt(seq)
      );

      if (category.length > 0) {
        return `menu=${this.menu}&category=${category[0].option_category2}`;
      }
      return `menu=${this.menu}&category=${this.category}`;
    },
    async Main() {
      await this.$store.dispatch("review/getRealTimeNews");
      await this.$store.dispatch("review/getNewsMenu");
      if (parseInt(this.menu) === parseInt(this.news)) {
        this.toMenu(this.menu, "", true, this.news);
      }
      if (parseInt(this.menu) === parseInt(this.sport)) {
        this.toMenu(this.menu, "", true, this.sport);
      }
      if (parseInt(this.menu) === parseInt(this.media)) {
        this.toMenu(this.menu, "", true, this.media);
      }
      if (parseInt(this.menu) === parseInt(this.realTime)) {
        this.toMenu(this.menu, "", true, null);
      }
      this.getNewsList(true);
    },
    async getNewsList(reset = false) {
      if (reset) {
        this.page = 0;
      } else {
        this.seq = 0;
      }
      await this.$store.dispatch("review/getNewsList", {
        range: this.range,
        page: this.page,
        sort: this.sort,
        menu: this.menu,
        seq: this.seq,
        category: this.category,
        reset,
      });
      if (this.result) {
        if (parseInt(this.seq)) {
          if (this.$refs[`listItem_${this.seq}`][0]) {
            this.$refs[`listItem_${this.seq}`][0].openDetail();
          }
        }
      }
      if (!this.result) {
        this.$toast.default(this.msg);
      }

      this.page++;
    },
    resetSeq() {
      this.seq = 0;
      this.sort = "default";
      if (parseInt(this.menu) === parseInt(this.realTime)) {
        this.sort = "desc";
      } else {
        if (parseInt(this.category) <= 0) {
          this.sort = "partTopScore";
        }
      }

      this.resetDetail();
      this.getNewsList(true);
    },
    reSetView() {
      this.seq = 0;
      this.sort = "default";
      this.range = "today";
      this.menu = 0;
      this.category = 0;
      this.newsShow = false;
      this.sportShow = false;
      this.mediaShow = false;
      this.realTimeShow = false;
      this.totalShow = true;
      this.menuName = null;
      this.categoryTitle = null;
      this.goPage();
      this.getNewsList(true);
    },
    toMenu(menu, event, render = false, menuNumber = null) {
      const level1Li = document.querySelectorAll(".catelist li");
      let seq = "";
      if (!render) {
        [...level1Li].map((li) => li.classList.remove("on"));
        event.target.closest("li").classList.add("on");
        seq = parseInt(event.target.dataset.parentSeq);
      } else {
        seq = parseInt(menu);
      }

      this.newsShow = false;
      this.sportShow = false;
      this.mediaShow = false;
      this.realTimeShow = false;
      this.menuName = null;
      this.resetDetail();
      if (!render) {
        this.category = 0;
        this.seq = 0;
      }
      this.range = "today";
      if (parseInt(this.seq) > 0 && this.$route.query.range === undefined) {
        this.range = "year";
      }
      this.menu = parseInt(menu);
      if (seq === 0) {
        this.sort = "default";
        this.totalShow = true;
      } else {
        this.setCategory(null, true);
        if (!render) {
          this.menuName = event.target.textContent;
        } else {
          const menuName = this.newsMenuList.find(
            (news) => parseInt(news.parent_seq) === parseInt(menuNumber)
          );
          if (menuName) {
            this.menuName = menuName.parent_title;
          }
        }
        this.sort = "partTopScore";
        this.totalShow = false;
        if (parseInt(this.category) > 0) {
          this.sort = "default";
        }
        if (seq === this.news) {
          this.newsShow = true;
        } else if (seq === this.sport) {
          this.sportShow = true;
        } else if (seq === this.media) {
          this.mediaShow = true;
        } else if (seq === this.realTime) {
          this.range = null;
          this.menu = this.realTime;
          this.sort = "desc";
          this.realTimeShow = true;
        }
      }
      if (!render) {
        this.goPage();
        this.getNewsList(true);
      }
    },
    setSort(sort) {
      this.seq = 0;
      this.sort = sort;
      this.resetDetail();
      this.getNewsList(true);
    },
    setRange(range) {
      this.seq = 0;
      this.range = range;
      this.resetDetail();
      this.getNewsList(true);
    },
    getMenu(id) {
      console.log(id);
    },
    setSeq(seq) {
      this.seq = seq;
      const detail = document.querySelector(`#item_detail_${seq}`);
      const div = document.querySelector(`#listItem_${seq}`);
      if (detail) {
        div.scrollIntoView({ behavior: "smooth" });
      }
      //this.goPage();
    },
    goRealTime(seq) {
      this.toMenu(this.realTime, null, true);
      this.seq = seq;
      this.category = 0;
      this.goPage();
      this.getNewsList(true);
    },
    goInit() {
      this.seq = 0;
      this.category = 0;
      this.toMenu(0, null, true);
      this.resetDetail();
      this.goPage();
      this.getNewsList(true);
    },
    setCategory(category, reset = false) {
      const categoryList = document.querySelectorAll(".category_list");
      let target = null;
      if (category !== null) {
        target = document.querySelector(`#category_${category}`);
      }

      [...categoryList].map((list) => {
        [...list.querySelectorAll("li")].map((li) => {
          if (target !== li) {
            li.classList.remove("on");
          }
        });
      });

      if (!reset) {
        if (target.classList.contains("on")) {
          target.classList.remove("on");
          this.category = 0;
          this.sort = "partTopScore";
          this.categoryName = null;
        } else {
          this.category = category;
          this.sort = "default";
          target.classList.add("on");
          this.categoryName = target.querySelector("a").textContent;
        }
        this.seq = 0;
        this.resetDetail();
        this.goPage();
        this.getNewsList(true);
      }
    },
    resetDetail() {
      const snsClass = document.querySelectorAll(".sns_box");
      const declPop = document.querySelectorAll(".declaration_pop");
      const replyList = document.querySelectorAll(".reply_list_box");
      const arrowReply = document.querySelectorAll(".arrow_reply");
      const infoWord = document.querySelectorAll(".info_word");
      const arrowInfo = document.querySelectorAll(".arrow_info");
      const detail = document.querySelectorAll(".detail");
      const detailArrow = document.querySelectorAll(".detail_arrow");

      [...infoWord].map((word) => {
        word.style.display = "none";
      });
      [...arrowInfo].map((info) => (info.textContent = "∨"));
      [...snsClass].map((sns) => {
        sns.style.display = "none";
      });
      [...declPop].map((decl) => {
        decl.style.display = "none";
      });
      [...replyList].map((reply) => {
        reply.style.display = "none";
      });
      [...arrowReply].map((reply) => (reply.textContent = "∨"));
      [...detailArrow].map((deatil) => (deatil.textContent = "∨"));
      [...detail].map((d) => (d.style.display = "none"));
    },
    goDetailList(option2) {
      this.setCategory(option2);
    },
    goPage() {
      this.$router.push(this.pageUrl).catch(() => {});
    },
  },
};
</script>

<style lang="scss" scoped>
.newsflash {
  position: relative;
  text-overflow: unset;
  .realtime_news {
    display: inline-block;
    line-height: 18px;
    overflow: hidden;
    height: 18px;
    position: absolute;
    margin-left: 5px;
  }
  .right_btn {
    float: right;
    background-color: #eee;
    padding: 0 10px;
    font-weight: 600;
    box-shadow: 3px 2px 3px grey;
  }
}

.sect_list {
  &.none_data {
    text-align: center;
    padding: 10px 0px 0px 0px;
  }
}
// .location {
//   .second {
//     font-weight: 600;
//   }
// }
.shape_red::before,
.shape_yellow::before {
  content: "";
  display: inline-block;
  width: 12px;
  height: 12px;
  margin: 0 2px;
  vertical-align: middle;
  border-radius: 50%;
}
.shape_yellow::before {
  background: $primary-color;
}
.shape_red::before {
  background: red;
}
</style>
